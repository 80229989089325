body {
  background-color: #efbd30;
}

.logo {
  width: 40%;
}

.large-text {
  font-size: large;
}

.top-padded {
  padding: 5%;
}

.crypt {
  unicode-bidi: bidi-override;
  direction: rtl;
}